<template>
    <cluji-card :starts-open="false" :title="title" :subtitle="subtitle">
        <div v-for="(item, index) in data" :key="'leaderboard_' + index"
             :class="{'leaderboard-line': true,
                    'leaderboard-gold': index === 0,
                    'leaderboard-silver': index === 1,
                    'leaderboard-bronze': index === 2}">
            <b-img-lazy :src="pictureSrc(item)"/>
            <p class="d-inline">
                <slot name="text" v-bind:item="item">
                    <user-name :user="item.utilisateur" v-if="item.utilisateur"/>
                    <span v-else-if="item.text">{{ item.text }}</span>
                    ({{ item.count }})
                </slot>
            </p>
        </div>
    </cluji-card>
</template>

<script>
    const UserName  = () => import('@/components/UserName');
    const ClujiCard = () => import('@/components/ClujiCard');

    import {pictureSrc} from '@/util/image';

    export default {
        name: "LeaderboardCard",
        components: {UserName, ClujiCard},
        props: {
            title: {
                type: String,
                required: true
            },
            subtitle: {
                type: String,
                default: null
            },
            data: {
                type: Array,
                required: true
            }
        },
        methods: {
            pictureSrc(item) {
                return item.image ? item.image : pictureSrc(item.utilisateur, 'utilisateurs');
            }
        }
    }
</script>

<style scoped>
    .leaderboard-line {
        border-radius: 1rem;
        height: 84px;
        padding: 10px;
        margin-bottom: 20px;
        line-height: 64px;
        border: 1px solid #eee;
    }

    .leaderboard-line img {
        border-radius: .5rem;
        width: 64px;
        height: 64px;
        object-fit: cover;
        margin-right: 20px;
        filter: drop-shadow(0 0 .15rem rgba(0, 0, 0, 0.5));
    }

    .leaderboard-line p {
        font-size: 20px;
    }

    .leaderboard-gold, .leaderboard-silver, .leaderboard-bronze {
        text-shadow: rgba(0, 0, 0, 0.5) 0 0 .25rem;
        color: white;
    }

    .leaderboard-gold {
        background-color: #ffc500;
    }

    .leaderboard-silver {
        background-color: #c3c3c3;
    }

    .leaderboard-bronze {
        background-color: #a44c00;
    }
</style>